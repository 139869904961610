import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import loginlogo from '../login/faviconbot.png';

export const LoginForm = () => {
  const [mobileNo, setMobileNo] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!mobileNo) {
      setError("Mobile number is required");
      return;
    }

    try {
      const response = await axios.post("https://api.rccnorth.in/login", { mobile_no: mobileNo });

      if (response.status === 200) {
        toast.success("Login successful!");
        setTimeout(() => {
          navigate(`/home?id=${response.data.id}&mobile_no=${response.data.mobile_no}&name=${response.data.name}`);
        }, 2000);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Wrong mobile number");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="backgroundimg">
      <div className="marginlogin">
        <div className="container-fluid">
          <div className="registration-Login">
            <div className="image-container1">
              <img src={loginlogo} className="center-image" alt="logologin"></img>
            </div>

            <h1 className="loginhead">Hello Rotarian</h1>
            <h2 className="loginhead1">Let's Get Started</h2>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <h5 className="label1">Enter your Mobile Number</h5>
            <input
              type="text"
              className="reg-form"
              placeholder="934-890-4983"
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button className="loginmbtn" onClick={handleLogin}>
              Proceed
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
